<template>
  <span v-if="isLoading || hasAlert">
    <b-spinner
      v-if="isLoading"
      variant="secondary"
      label="Verificando alterações de produtos/impostos..."
      small
    ></b-spinner>
    <template v-else>
      <i
        :id="'alert' + orcamentoItemId"
        class="fa fa-exclamation-triangle text-danger d-inline"
      ></i>
      <b-popover
        :target="'alert' + orcamentoItemId"
        triggers="hover"
        placement="auto"
      >
        <template v-slot:title>Atenção</template>
        <p v-if="type.price">
          O preço do produto foi alterado. Abra o orçamento para edição e clique em Atualizar Produtos!
        </p>
        <p v-if="type.stock">
          O produto está sem estoque. Por favor o remova antes de prosseguir.
        </p>
        <p v-if="type.priceMin">
          {{message}}
        </p>
        <p v-if="type.multiple">
          {{message}}
        </p>
        <p v-if="data == null">
          Não foi possível fazer a consulta de mudanças de produtos, tente
          novamente mais tarde.
        </p>
      </b-popover>
    </template>
  </span>
</template>

<style scoped>
.table-container {
  height: 15rem;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>

<script>
import Orcamento from "../../../services/orcamento";
import { EventBus } from "../../../helpers/eventBus";

export default {
  name: "OrcamentoItemAlerta",
  props: {
    id: {
      type: String,
      default: "OrcamentoItemAlerta"
    },
    name: {
      type: String,
      default: "OrcamentoItemAlerta"
    },
    orcamentoId: {
      type: Number,
      default: 0
    },
    orcamentoItemId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasAlert() {
      return (this.type.price || this.type.stock || this.type.priceMin || this.type.multiple);
    }
  },
  data: () => ({
    isLoading: false,
    data: null,
    message: null,
    type: {
      price: false,
      stock: false,
      priceMin: false,
      multiple: false
    }
  }),
  mounted() {
    this.getProductItemChangeAlerts(this.orcamentoId, this.orcamentoItemId);
  },
  methods: {
    getProductItemChangeAlerts(orcamento_id, orcamento_item_id) {
      let vue = this;
      this.isLoading = true;
      this.data = null;
      Orcamento.getProductItemChangeAlerts(orcamento_id, orcamento_item_id)
        .then(response => {
          vue.data = response.data.data != null ? response.data.data : [];
          vue.type.price = ( (Array.isArray(vue.data) && vue.data.length > 0) || (!Array.isArray(vue.data) && Object.keys(vue.data).length > 0) );
          EventBus.$emit("onBudgetItemHasChanges", {
            orcamento_item_id: orcamento_item_id,
            changed: vue.hasAlert
          });
        })
        .catch(e => {
          if (
            !(
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            )
          ) {
            this.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          vue.getProductBudgetOutOfStock(orcamento_id, orcamento_item_id);
        });
    },
    getProductBudgetOutOfStock(orcamento_id, orcamento_item_id){
      let vue = this;

       Orcamento.getProductBudgetOutOfStock(orcamento_id, orcamento_item_id)
        .then(response => {
          vue.data = response.data.data;
          vue.type.stock = ( (Array.isArray(vue.data) && vue.data.length > 0) || (!Array.isArray(vue.data) && Object.keys(vue.data).length > 0) );
          EventBus.$emit("onBudgetItemHasChanges", {
              orcamento_item_id: orcamento_item_id,
              changed: false
            });
        })
        .catch(e => {
          if (
            !(
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            )
          ) {
            vue.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          vue.getProductPriceMinInvalid(orcamento_id, orcamento_item_id);
        });
    },
    getProductPriceMinInvalid(orcamento_id, orcamento_item_id){
      let vue = this;

       Orcamento.getProductBudgetPriceMin(orcamento_id, orcamento_item_id)
        .then(response => {
          vue.data = response.data.data;
          if(Array.isArray(vue.data) && vue.data.length > 0){
            vue.type.priceMin = true;
            vue.message = vue.data[0][0].mensagem != undefined ? vue.data[0][0].mensagem : "Para esse produto o valor do item está abaixo do mínimo permitido.";
          } else if(!Array.isArray(vue.data) && Object.keys(vue.data).length > 0){
            vue.type.priceMin = true;
            vue.message = vue.data[Object.keys(vue.data)][0].mensagem != undefined ? vue.data[Object.keys(vue.data)][0].mensagem : "Para esse produto o valor do item está abaixo do mínimo permitido.";
          } else {
            vue.type.priceMin = false;
            vue.message = null;
          }

          EventBus.$emit("onBudgetItemHasChanges", {
              orcamento_item_id: orcamento_item_id,
              changed: false
            });

          EventBus.$emit("priceMin", {
            validaPrecoMin: vue.type.priceMin
          });
        })
        .catch(e => {
          if (
            !(
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            )
          ) {
            vue.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          vue.getProductMultiple(orcamento_id, orcamento_item_id);
        });
    },
    getProductMultiple(orcamento_id, orcamento_item_id){
      let vue = this;

      Orcamento.getProductMultiple(orcamento_id, orcamento_item_id)
        .then(response => {
          vue.data = response.data.data;

          if(!Array.isArray(vue.data) && Object.keys(vue.data).length > 0) {
            vue.type.multiple = true;
            vue.message = vue.data[Object.keys(vue.data)][0].mensagem != undefined ? vue.data[Object.keys(vue.data)][0].mensagem : "Verifique o múltiplo deste produto.";
          }
          
          EventBus.$emit("onBudgetItemHasChanges", {
              orcamento_item_id: orcamento_item_id,
              changed: false
            });
        })
        .catch(e => {
          if (
            !(
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            )
          ) {
            vue.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          vue.isLoading = false;

          EventBus.$emit("finishLoad", {
            finish: true
          });
        });
    }
  }
};
</script>
