<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-list"></i>
            Orçamento
            <b-button
              v-if="canPrint"
              v-on:click="printOrcamento"
              :disabled="veriricaStatusOrcamento"
              size="sm"
              class="float-right"
              variant="primary"
            >
              <i class="fa fa-print"></i> Imprimir Proposta
            </b-button>

            <b-button
              v-on:click="duplicarOrcamento"
              size="sm"
              class="float-right mr-3"
              variant="primary"
            >
              <i class="fa fa-copy"></i> Duplicar Orçamento
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="2">
                <b-form-group label="Código" label-for="id">
                  <b-form-input
                    id="id"
                    type="text"
                    :value="orcamento.id"
                    placeholder="Código"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  v-if="clientStatus != null"
                  label="Status"
                  label-for="status"
                >
                  <h3>
                    <orcamento-status id="status" :status="orcamentoStatus" />
                  </h3>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Data de Emissão" label-for="data_emissao">
                  <b-form-input
                    id="data_emissao"
                    type="text"
                    :value="orcamento.data_emissao | formatDate"
                    placeholder="Data de Emissão"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Data de Validade*"
                  label-for="data_validade"
                >
                  <my-date-time
                    :format="'dd/MM/yyyy'"
                    :type="'date'"
                    v-model="dataValidadeOrcamento"
                    :title="'Data de Validade:'"
                    readonly
                  ></my-date-time>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Previsão de Fechamento"
                  label-for="previsao_fechamento"
                >
                  <b-form-input
                    id="previsao_fechamento"
                    :type="'date'"
                    v-model="dataPrevisaoFechamento"
                    placeholder="Previsão de Fechamento"
                    @blur="alteraPrevFechamento()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Contato*" label-for="contato">
                  <b-form-input
                    id="contato"
                    type="text"
                    v-model="orcamento.contato"
                    :readonly="!isEditing"
                    placeholder="Contato"
                    @change="atualizarCabecalhoOrcamento()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group label="CNPJ*" label-for="cnpj">
                  <b-form-input
                    id="cnpj"
                    type="text"
                    :value="clientBasicInfo.cnpj | cnpj"
                    placeholder="CNPJ"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group label="Razão Social" label-for="razao_social">
                  <b-form-input
                    id="razao_social"
                    type="text"
                    :value="clientBasicInfo.razao_social"
                    placeholder="Razão Social"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  v-if="clientStatus != null"
                  label="Status do Cliente"
                  label-for="status"
                >
                  <h3>
                    <cliente-status id="status" :status="clientStatus" />
                  </h3>
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="!productsVisible">
              <b-row>
                <b-col>
                  <b-card no-body>
                    <b-card-header class="p-2">
                      <i class="icon-list ml-1"></i>
                      <span
                        ><b>{{ getFilial() }}</b></span
                      >
                      <b-button
                        v-if="isEditing && !disabled"
                        v-on:click="showProducts"
                        size="sm"
                        class="float-right"
                        variant="primary"
                      >
                        <i class="fa fa-plus"></i> Adicionar Produtos
                      </b-button>
                    </b-card-header>
                    <b-card-body class="orcamento-table-card-body font-12">
                      <b-table
                        class="w-100 orcamento-footer mb-0"
                        :items="orcamentoItens"
                        :fields="fields"
                        v-on:sort-changed="onSortChanged"
                        small
                        hover
                        bordered
                        :responsive="true"
                        :sort-by.sync="sortBy"
                        :sort-direction.sync="sortDirection"
                        :foot-clone="true"
                        :no-footer-sorting="true"
                        :no-sort-reset="true"
                        stacked="sm"
                        ref="cartTable"
                      >
                        <template #thead-top="">
                          <b-tr>
                            <b-th
                              class="text-center"
                              variant="default"
                              colspan="2"
                            >
                              Informações
                            </b-th>
                            <b-th
                              class="text-center"
                              variant="default"
                              colspan="8"
                            >
                              Valores
                            </b-th>
                            <b-th></b-th>
                          </b-tr>
                        </template>
                        <template v-slot:cell(id)="data">
                          <div class="btn-flex">
                            <b-button
                              v-if="isEditing"
                              class="my-data-table-row-button"
                              size="sm"
                              variant="primary"
                              v-on:click="editBudgetItem(data.item)"
                            >
                              <i class="fa fa-edit"></i>
                            </b-button>
                            <b-button
                              v-if="isEditing"
                              class="my-data-table-row-button"
                              size="sm"
                              variant="danger"
                              v-on:click="confirmDeleteItem(data.item)"
                            >
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </div>
                        </template>
                        <template v-slot:cell(titulo)="data">
                          <div class="titulo-produto">
                            <div
                              class="float-left titulo-produto-ellipsis font-12"
                            >
                              {{ data.item.titulo }}
                            </div>
                            <orcamento-item-alerta
                              :key="data.item.id"
                              :id="'orcamentoItemAlertaID' + data.item.id"
                              v-if="canEdit"
                              class="float-right"
                              :orcamentoId="data.item.orcamento_id"
                              :orcamentoItemId="data.item.id"
                            ></orcamento-item-alerta>
                          </div>
                        </template>
                        <template v-slot:cell(valor_unitario)="data">
                          {{ data.item.valor_unitario | money }}
                        </template>
                        <template v-slot:cell(margem_valor)="data">
                          {{ data.item.margem_valor | money }}
                        </template>
                        <template v-slot:cell(valor_ipi)="data">
                          {{
                            (data.item.valor_ipi + data.item.valor_margem_ipi)
                              | money
                          }}
                        </template>
                        <template v-slot:cell(valor_st)="data">
                          {{
                            (data.item.valor_st + data.item.valor_margem_st)
                              | money
                          }}
                        </template>
                        <template v-slot:cell(valor_bruto_unit)="data">
                          {{
                            (data.item.valor_unitario +
                              data.item.margem_valor +
                              (data.item.valor_ipi +
                                data.item.valor_margem_ipi) +
                              (data.item.valor_st + data.item.valor_margem_st))
                              | money
                          }}
                        </template>
                        <template v-slot:cell(valor_total)="data">
                          {{ data.item.valor_total | money }}
                        </template>
                        <template v-slot:cell(valor_comissao)="data">
                          {{ getValorComissao(data.item) | money }}
                        </template>
                        <template v-slot:foot(titulo)>
                          <div class="w-100" id="info-frete">
                            Para compras no valor acima de R$ 2.000,00, o frete
                            é pago pela Dicomp, com exceção para racks e
                            cabos.<br />
                            Para compras de racks e cabos, o frete será por
                            conta do destinatário.
                          </div>
                        </template>
                        <template v-slot:foot()>
                          <span class="text-white">-</span>
                        </template>
                        <template v-slot:foot(margem_valor)>Totais:</template>
                        <template v-slot:foot(valor_total)>
                          <div class="w-100 text-right moeda-real">
                            {{ valorTotalSum | money }}
                          </div>
                        </template>
                        <template v-slot:foot(valor_comissao)>
                          <div class="w-100 text-right moeda-real">
                            {{ comissaoSum | money }}
                          </div>
                        </template>
                      </b-table>
                      <div class="d-block w-100 mt-3 d-sm-none">
                        <div class="w-100 border d-flex pt-2 pb-2">
                          <div
                            class="float-left w-40 text-right font-weight-bold pr-2 pl-2"
                          >
                            Valor Total
                          </div>
                          <div class="float-left w-60">
                            <div class="w-100 text-right moeda-real pr-2 pl-2">
                              {{ valorTotalSum | money }}
                            </div>
                          </div>
                        </div>
                        <div class="w-100 border d-flex pt-2 pb-2">
                          <div
                            class="float-left w-40 text-right font-weight-bold pr-2 pl-2"
                          >
                            Comissão Total
                          </div>
                          <div class="float-left w-60">
                            <div class="w-100 text-right moeda-real pr-2 pl-2">
                              {{ comissaoSum | money }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <forma-pgto
                :key="exibirPagamentoSelecionado"
                :status-orcamento="formaPagamentoEditing"
                :forma-pagamento="this.orcamento.forma_pgto"
                :cond-pagamento="this.orcamento.cond_pgto"
                :obs="this.orcamento.observacao"
              >
              </forma-pgto>
              <hr />
              <b-row>
                <b-col v-if="canCancel" md="2">
                  <b-button
                    v-on:click="$bvModal.show('cancelConfirm')"
                    class="w-100 mb-2"
                    variant="danger"
                  >
                    <i class="fa fa-times"></i> Cancelar Orçamento
                  </b-button>
                </b-col>
                <b-col></b-col>
                <b-col v-if="hasProductChanges" md="3">
                  <b-button
                    v-on:click="confirmFixProduts"
                    variant="primary"
                    class="w-100 mb-2"
                  >
                    <i class="fa fa-refresh"></i> Atualizar Produtos
                  </b-button>
                </b-col>
                <b-col md="3">
                  <b-button
                    v-on:click="confirmSend"
                    v-if="!disabled"
                    :disabled="!canSend"
                    variant="primary"
                    class="w-100 mb-2"
                  >
                    <i class="fa fa-send"></i> Enviar Pedido
                  </b-button>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="12">
                  <div class="w-100" id="info-vlMinimo">
                    Para enviar o pedido, o valor mínimo deve ser de R$ 600,00,
                    considerando a Quantidade, o Valor unitário dos produtos e a
                    Margem.
                  </div>
                </b-col>
              </b-row>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="cancelConfirm"
      title="Atenção"
      buttonSize="sm"
      okVariant="success"
      okTitle="Sim"
      @ok="confirmCancel()"
      cancelVariant="danger"
      cancelTitle="Não"
      @cancel="fechaModalCancel()"
      footerClass="p-2"
      :hideHeaderClose="modal.hideHeaderClose"
      :centered="modal.centered"
    >
      <div class="confirmacao">
        Tem certeza que deseja cancelar Orçamento Nº. {{ orcamento_id }}?
      </div>
      <div class="my-3 form-group">
        <label for="" style="font-weight: bold"
          >Motivo do cancelamento (opcional)</label
        >
        <textarea
          v-model="motivoCancel"
          id=""
          cols="70"
          rows="3"
          placeholder="Escreva sua mensagem aqui"
          class="form-control"
          @keyup="charCount()"
        ></textarea>
        <p>{{ tamRest }} caracteres restante</p>
      </div>
    </b-modal>
    <b-row v-if="productsVisible">
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <span> <i class="icon-list"></i> Lista de Produtos </span>
          </b-card-header>
          <b-card-body>
            <product-list
              id="produtosList"
              ref="produtosList"
              :cd_filial="filial"
              :cliente_codigo="clientBasicInfo.codigo"
              :resultsPerPage="10"
              v-on:onAdd="onAdd"
              v-on:onAddMga="onAddMga"
              v-on:onAddItj="onAddItj"
            >
              <template #segundaColuna>
                <b-btn
                  :variant="getVariant()"
                  class="d-flex justify-content-between align-items-center w-100"
                  v-on:click="hideProducts"
                >
                  <template v-if="!isAdding()">
                    <i class="icons cui-cart mr-1"></i>
                    R$ {{ valorTotalSum | money }}
                    <b-badge class="ml-1" variant="light">{{
                      QtdeTotalSum
                    }}</b-badge>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      <b-spinner
                        variant="secondary"
                        label="Totalizando..."
                        small
                      ></b-spinner>
                    </div>
                  </template>
                </b-btn>
              </template>
              <template #terceiraColuna v-if="QtdeTotalSum > 0">
                <b-btn class="w-100" variant="success" v-on:click="hideProducts"
                  >Mostrar Lista de Produtos</b-btn
                >
              </template>
            </product-list>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <orcamento-item-edit
      ref="orcamentoItemEdit"
      id="orcamentoItemEdit"
      v-model="currentOrcamentoItem"
      v-on:itemSaved="onItemSaved"
      :codigoCliente="orcamento.cliente_codigo"
      :idOrcamento="orcamento.id"
    ></orcamento-item-edit>
  </div>
</template>

<style>
.orcamento-table-card-body {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 !important;
  font-size: 12px;
}
.orcamento-footer tfoot,
.orcamento-footer tfoot > tr,
.orcamento-footer tfoot > tr > th {
  border: none;
  font-size: 0.8rem;
}
.orcamento-footer tfoot > tr > th:nth-child(12),
.orcamento-footer tfoot > tr > th:nth-child(13) {
  border: 1px solid #c8ced3;
}
.orcamento-footer tfoot > tr > th:nth-child(11),
.orcamento-footer tfoot > tr > th:nth-child(12),
.orcamento-footer tfoot > tr > th:nth-child(13) {
  text-align: right;
}
.orcamento-table-card-body tbody tr:nth-of-type(odd) .table-success {
  background-color: #cdedd8;
}
.orcamento-table-card-body tbody tr:nth-of-type(even) .table-success {
  background-color: rgba(205, 237, 216, 0.6);
}
.orcamento-table-card-body tbody tr:nth-of-type(odd) .table-info {
  background-color: #d3eef6;
}
.orcamento-table-card-body tbody tr:nth-of-type(even) .table-info {
  background-color: rgba(211, 238, 246, 0.6);
}
.orcamento-table-card-body tfoot tr th {
  background-color: white;
}
.orcamento-table-card-body tfoot tr:last-child th:hover {
  background-color: white;
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titulo-produto {
  display: flex;
  justify-content: space-between;
}
.titulo-produto-ellipsis {
  max-width: 20em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#info-frete {
  color: red;
  font-weight: 500;
}

#info-vlMinimo {
  color: blue;
  font-weight: 600;
}

.font-12 {
  font-size: 12px !important;
}

.icone-observacao {
  border: none !important;
  background: none !important;
  color: #3131bb;
}

.div-oberervacao {
  width: 95% !important;
  margin-left: 2%;
  margin-bottom: 2%;
}

.sem-borda:focus {
  border: none;
}

.btn-primary {
  background-color: #0075eb !important;
}

.btn-warning {
  background-color: #ff6d13 !important;
  color: #fff !important;
}
</style>

<script>
import "../../../assets/css/DataTableRowButton.css";
import Orcamento from "../../../services/orcamento";
import Cliente from "../../../services/cliente";
import ClienteStatus from "../clientes/ClienteStatus";
import OrcamentoStatus from "./OrcamentoStatus";
import OrcamentoItemEdit from "./OrcamentoItemEdit";
import OrcamentoItemAlerta from "./OrcamentoItemAlerta";
import Pedido from "../../../services/pedido";
import MyDateTime from "../../../components/ui/MyDateTime";
import ProductList from "../produtos/ProductList";
import { EventBus } from "../../../helpers/eventBus";
import FormaPgto from "../FormaPgto";
import GrupoPermissao from "../../../services/grupoPermissao";

export default {
  name: "Orcamento",
  components: {
    ClienteStatus,
    OrcamentoStatus,
    OrcamentoItemEdit,
    OrcamentoItemAlerta,
    MyDateTime,
    ProductList,
    FormaPgto,
  },
  data: () => ({
    dataValidadeOrcamento: "",
    dataPrevisaoFechamento: "",
    resp: "",
    productChangesReturned: 0,
    productsReturn: [],
    hasProductChanges: false,
    productsVisible: false,
    valida: false,
    show: false,
    adding: [],
    sortBy: "produto_codigo",
    exibe_observacao: false,
    sortDirection: "asc",
    orcamento: new Object(),
    orcamentoItens: [],
    currentOrcamentoItem: new Object(),
    orcamento_id: null,
    clientBasicInfo: new Object(),
    filial: "",
    tamRest: 1000,
    motivoCancel: "",
    vlMinimoOrcamento: 0,
    fields: [
      {
        key: "produto_codigo",
        label: "Cód.",
        sortable: true,
        tdClass: "text-right",
        thClass: "text-center",
      },
      { key: "titulo", label: "Descrição", sortable: true },
      {
        key: "quantidade",
        label: "Qtde.",
        sortable: true,
        tdClass: "text-right",
        thClass: "text-center",
      },
      {
        key: "valor_unitario",
        label: "Vl.Unit. S/ ipi",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "margem_valor",
        label: "Margem Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "valor_ipi",
        label: "Valor Ipi Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "valor_st",
        label: "Valor St Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "valor_bruto_unit",
        label: "Valor Venda Unitário",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "valor_total",
        label: "Valor Venda Total",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "valor_comissao",
        label: "Prêmio",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
      {
        key: "id",
        label: "Ações",
        sortable: false,
        tdClass: "text-center",
        visible: true,
        thClass: "text-center",
      },
    ],
    modal: {
      hideHeaderClose: false,
      centered: true,
    },
    disabled: false
  }),
  computed: {
    canEdit() {
      return this.orcamento.status == "D" || this.orcamento.status == "S";
    },
    canSend() {
      return (
        !this.hasProductChanges &&
        this.orcamentoItens.length == this.productChangesReturned &&
        this.vlMinimoOrcamento >= 600
      );
    },
    veriricaStatusOrcamento() {
      return (
        (this.orcamento.status == "C" ? true : false) ||
        this.valida ||
        this.hasProductChanges
      );
    },
    canPrint() {
      return this.orcamento.status != "C" && this.show;
    },
    canCancel() {
      return this.orcamento.status == "D" || this.orcamento.status == "S";
    },
    isEditing() {
      return this.orcamento.status == "D";
    },
    valorTotalSum() {
      return this.orcamentoItens.reduce(function (total, item) {
        return total + item.valor_total * 1;
      }, 0);
    },
    QtdeTotalSum() {
      return this.orcamentoItens.reduce(function (total, item) {
        return total + item.quantidade * 1;
      }, 0);
    },
    comissaoSum() {
      let vue = this;
      return this.orcamentoItens.reduce(function (total, item) {
        return total + vue.getValorComissao(item);
      }, 0);
    },
    clientStatus() {
      return this.clientBasicInfo.status;
    },
    orcamentoStatus() {
      return this.orcamento.status;
    },
    formaPagamentoEditing() {
      if (this.orcamento.status == "D") {
        return false;
      }
    },
    exibirPagamentoSelecionado() {
      if (this.orcamento.forma_pgto != "") {
        return this.orcamento.forma_pgto;
      }
    },
  },
  beforeMount() {
    this.validaPermissao();
    this.orcamento_id = this.$route.params.id;
    this.loadBudget(this.orcamento_id);

    let vue = this;

    EventBus.$on("onBudgetItemHasChanges", (item) => {
      let index = vue.productsReturn.findIndex(
        (el) => el == item.orcamento_item_id
      );
      if (index == -1) {
        vue.productChangesReturned++;
      }
      vue.productsReturn.push(item.orcamento_item_id);
      if (item.changed) vue.hasProductChanges = true;
    });

    EventBus.$on("sendOrcamento", this.sendOrcamento);

    EventBus.$emit("finishLoad", {
      finish: false,
    });

    window.onbeforeunload  =  function(){ 
      if (vue.$store.state.ui.salvandoPedido) {
        return "";
      }
    }
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/orcamentos").then((response) => {
        if (!response.data.data || response.data.data.edita != "1")
          this.$router.push("/");
      });
    },
    showHideColumnId() {
      {
        let index = this.fields.findIndex((x) => x.key == "id");
        if (!this.isEditing) {
          this.$set(this.fields, index, {
            key: "id",
            label: "Ações",
            sortable: false,
            thClass: "d-none",
            tdClass: "d-none",
          });
        } else {
          this.$set(this.fields, index, {
            key: "id",
            label: "Ações",
            sortable: false,
            thClass: null,
            tdClass: "text-center",
          });
        }
      }
    },
    loadBudget(id) {
      this.orcamento = new Object();
      Orcamento.getByCodigo(id)
        .then((response) => {
          this.orcamento = response.data.data;
          this.dataValidadeOrcamento = response.data.data.data_validade;
          this.dataPrevisaoFechamento = response.data.data.previsao_fechamento;
          this.observacao_text = response.data.data.observacao;
          this.filial = response.data.data.cd_filial;
          this.loadClientBasicInfo(this.orcamento.cliente_codigo);
          this.loadOrcamentoItens(this.orcamento.id);
          this.showHideColumnId();

          if (this.filial == 1) {
            this.$store.commit(
              "carrinho/CURRENT_CLIENT",
              this.orcamento.cliente_codigo
            );
          } else {
            this.$store.commit(
              "carrinhoitj/CURRENT_CLIENT_ITJ",
              this.orcamento.cliente_codigo
            );
          }
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    async loadOrcamentoItens(orcamento_id) {
      if (orcamento_id == null || orcamento_id == 0) return;
      this.orcamentoItens = [];
      this.productChangesReturned = 0;
      await Orcamento.getOrcamentoItens(orcamento_id)
        .then((response) => {
          this.orcamentoItens = response.data.data;

          this.orcamentoItens.forEach((el) => {
            el.ultimaMargem = {
              margemValor: el.margem_valor,
              margemPercentual: el.margem_percentual,
            };
          });

          this.vlMinimoOrcamento = response.data.valor_unitario_total;

          this.validaBot(this.orcamentoItens);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.productsVisible = true;
            return;
          }
          this.$helper.showErrorResponse(e);
        });
    },
    validaBot(value) {
      for (let i = 0; i < value.length; i++) {
        let valor_unit =
          value[i].valor_unitario +
          value[i].margem_valor +
          value[i].valor_ipi +
          value[i].valor_st;
        if (value[i].bo_registro == "false") {
          if (
            value[i].vl_liquido_tab_coorp != 0 &&
            value[i].vl_liquido_tab_coorp > valor_unit
          ) {
            this.valida = true;
          }
        }
        this.show = true;
      }
    },
    loadClientBasicInfo(cliente_codigo) {
      if (cliente_codigo == null || cliente_codigo == 0) return;
      this.clientBasicInfo = new Object();
      Cliente.getBasicInfo(cliente_codigo)
        .then((response) => {
          this.clientBasicInfo = response.data.data;
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    charCount() {
      this.tamRest = 1000 - this.motivoCancel.length;
    },
    onSortChanged() {},
    confirmDeleteItem(row) {
      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que deseja excluir o produto Nº." +
            row.produto_codigo +
            "?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: this.modal.hideHeaderClose,
            centered: this.modal.centered,
          }
        )
        .then((value) => {
          if (value) {
            let vue = this;
            Orcamento.deleteOrcamentoItem(row.orcamento_id, row.id)
              .then((response) => {
                this.$helper.showMsg(response.data.msg, response.data.type);
                let index = vue.orcamentoItens.findIndex((x) => x.id == row.id);
                vue.orcamentoItens.splice(index, 1);

                vue.productChangesReturned = 0;
                let list = [];

                vue.productsReturn.forEach((value, index) => {
                  if (value == row.id) {
                    vue.productsReturn.splice(index, 1);
                  }

                  if (
                    list.findIndex((item) => item == value) == -1 &&
                    value != row.id
                  ) {
                    list.push(value);
                  }
                });
                vue.productChangesReturned = list.length;
              })
              .catch((e) => {
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
    confirmCancel() {
      Orcamento.cancelOrcamento(this.orcamento.id, this.motivoCancel)
        .then((response) => {
          this.$helper.showMsg(response.data.msg, response.data.type);
          this.$router.push("/vendas/orcamentos");
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    fechaModalCancel() {
      this.tamRest = 1000;
      this.motivoCancel = "";
      $bvModal.hide("cancelConfirm");
    },
    confirmFixProduts() {
      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que atualizar os produtos do Orçamento Nº. " +
            this.orcamento.id +
            "?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Sim",
            cancelVariant: "danger",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: this.modal.hideHeaderClose,
            centered: this.modal.centered,
          }
        )
        .then((value) => {
          if (value) {
            Orcamento.updateProducts(this.orcamento.id)
              .then((response) => {
                this.hasProductChanges = false;
                this.loadOrcamentoItens(this.orcamento.id);
                this.$helper.showMsg(response.data.msg, response.data.type);
                this.productsReturn.splice(0, this.productsReturn.length);
              })
              .catch((e) => {
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
    getValorComissao(item) {
      return item.valor_comissao;
    },
    confirmSend() {
      if (this.clientBasicInfo.status != "A") {
        this.$helper.showMsg(
          "Para enviar o orçamento o Status do cliente deve ser Ativo.",
          "warning"
        );
        return;
      }

      if (
        this.orcamento.data_validade == null ||
        this.orcamento.data_validade.trim() == ""
      ) {
        this.$helper.showMsg(
          "Para enviar o orçamento informe a Data de Validade.",
          "warning"
        );
        return;
      }

      let itensSemMargem = false;
      let itensNaoMultiplos = false;

      this.orcamentoItens.forEach((item) => {
        if (item.margem_valor <= 0) {
          itensSemMargem = true;
        }

        if (item.quantidade % item.multiplo != 0) {
          itensNaoMultiplos = true;
        }
      });

      if (itensNaoMultiplos) {
        this.$helper.showMsg(
          "Há algum produto com quantidade não adequada ao seu múltiplo.",
          "warning",
          "Produto com múltiplo divergente."
        );
        return;
      }

      if (itensSemMargem) {
        this.$bvModal
          .msgBoxConfirm(
            "Não foi informado um valor de premiação a ser recebido nesse orçamento. Deseja enviar sem o valor de premiação?",
            {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              okTitle: "Sim",
              cancelVariant: "danger",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: this.modal.hideHeaderClose,
              centered: this.modal.centered,
            }
          )
          .then((value) => {
            let vue = this;
            if (value) {
              if (this.orcamento.status == "D") {
                var self = this;
                this.saveOrcamento(function () {
                  self.salvarObservacaoePagamento();
                });
              }
            }
          })
          .catch((err) => {
            this.$helper.showErrorResponse(err);
          });
      } else {
        this.$bvModal
          .msgBoxConfirm(
            "Tem certeza que deseja enviar Orçamento Nº. " +
              this.orcamento.id +
              ", depois de enviado não será mais possível alterá-lo?",
            {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              okTitle: "Sim",
              cancelVariant: "danger",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: this.modal.hideHeaderClose,
              centered: this.modal.centered,
            }
          )
          .then((value) => {
            let vue = this;
            if (value) {
              if (this.orcamento.status == "D") {
                var self = this;
                this.saveOrcamento(function () {
                  self.salvarObservacaoePagamento();
                });
              }
            }
          })
          .catch((err) => {
            this.$helper.showErrorResponse(err);
          });
      }
    },
    getVariant() {
      return this.filial == 1 ? "primary" : "warning";
    },
    getFilial() {
      return this.filial == 1 ? "Maringá - PR" : "Itajaí - SC";
    },
    saveOrcamento(executeIfSucess = null) {
      Orcamento.saveOrcamento(this.orcamento, this.filial)
        .then((response) => {
          if (executeIfSucess == null) {
            this.$router.push("/vendas/orcamentos");
          } else {
            executeIfSucess();
          }
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    salvarObservacaoePagamento() {
      EventBus.$emit("salvarObsePgtoPedido");
    },
    sendOrcamento() {
      this.$store.commit('ui/SALVANDO_PEDIDO', true);
      Orcamento.sendOrcamento(this.orcamento.id)
        .then((response) => {
          this.salvarPedido();
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    salvarPedido() {
      let data = {
        orcamento: this.orcamento,
        itens: this.orcamentoItens,
        cd_filial: this.cd_filial,
        valor_total: this.valorTotalSum,
      };

      Pedido.salvePedido(data)
      .then((response) => {
        this.$helper.showMsg('Pedido enviado com sucesso!', 'success');
        this.$router.push("/vendas/orcamentos");
      })
      .catch((e) => {
        this.$helper.showErrorResponse('');
      })
      .finally(() => {
        this.$store.commit('ui/SALVANDO_PEDIDO', false);
      });
    },
    editBudgetItem(item) {
      this.currentOrcamentoItem = Object.assign({}, item);
      this.$bvModal.show(this.$refs.orcamentoItemEdit.id);
    },
    onItemSaved(item) {
      this.currentOrcamentoItem = item;
      let index = this.orcamentoItens.findIndex((x) => x.id == item.id);
      this.$set(this.orcamentoItens, index, item);
    },
    printOrcamento() {
      Orcamento.printOrcamento(this.orcamento.id)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          let windowPrint = window.open(fileURL, "about:blank");
          windowPrint.onload = function () {
            windowPrint.print();
          };
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    isAdding(produto_codigo = null) {
      if (produto_codigo == null) return this.adding.length > 0;
      else return this.adding.includes(produto_codigo);
    },
    onAddMga(data) {
      this.onAdd(data);
    },
    onAddItj(data) {
      this.onAdd(data);
    },
    onAdd(data) {
      this.onStartAdding(data.codigo);
      let addItem = {
        produto_codigo: data.codigo,
        quantidade: data.quantidade,
        cd_filial: this.filial,
        cd_empresa: 1,
      };
      Orcamento.addOrcamentoItem(this.orcamento.id, addItem)
        .then((response) => {
          let newItem = response.data.data;
          newItem.titulo = data.titulo;
          newItem.valor_bruto_unit =
            newItem.valor_unitario * 1 +
            newItem.valor_ipi * 1 +
            newItem.valor_st * 1 +
            newItem.margem_valor * 1;
          newItem.valor_total =
            newItem.quantidade * 1 * newItem.valor_bruto_unit;
          let index = this.orcamentoItens.findIndex((x) => x.id == newItem.id);
          if (index > -1) {
            this.$set(this.orcamentoItens, index, newItem);
          } else {
            this.orcamentoItens.push(newItem);
          }
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        })
        .finally(() => {
          this.onEndAdding(data.codigo);
        });
    },
    onStartAdding(produto_codigo) {
      this.adding.push(produto_codigo);
    },
    onEndAdding(produto_codigo) {
      this.adding = this.adding.filter(function (value) {
        return value != produto_codigo;
      });
    },
    showProducts() {
      this.$store.state.carrinho.cliente_codigo = this.orcamento.cliente_codigo;
      this.productsVisible = true;
    },
    hideProducts() {
      location.reload();
      this.productsVisible = false;
    },
    alteraPrevFechamento() {
      const data_prev = new Date(this.dataPrevisaoFechamento);
      const data_atual = new Date();
      data_atual.setUTCHours(0, 0, 0, 0);
      if (data_prev.getTime() < data_atual.getTime()) {
        this.$helper.showMsg(
          "A data de previsão de fechamento não pode ser menor que a data atual.",
          "warning"
        );
        return;
      }
      this.orcamento.previsao_fechamento = this.dataPrevisaoFechamento;
      this.atualizarCabecalhoOrcamento();
    },
    atualizarCabecalhoOrcamento() {
      Orcamento.saveOrcamento(this.orcamento);
    },
    duplicarOrcamento() {
      Orcamento.duplicarOrcamento(this.orcamento)
        .then((response) => {
          this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
  },
  watch: {
    dataValidadeOrcamento() {
      this.orcamento.data_validade = this.dataValidadeOrcamento;
      this.atualizarCabecalhoOrcamento();
    },
    checkEditPayment() {
      console.log(this.orcamento.status);
    },
  },
  beforeDestroy() {
    EventBus.$off("sendOrcamento", this.sendOrcamento);
  },
};
</script>
