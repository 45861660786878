<template>
  <b-badge :variant="buildStatus.type">{{ buildStatus.text }}</b-badge>
</template>

<script>
export default {
  name: "OrcamentoStatus",
  props: {
    id: {
      type: String,
      default: "OrcamentoStatus"
    },
    name: {
      type: String,
      default: "OrcamentoStatus"
    },
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    buildStatus() {
      let status = {
        text: "",
        type: ""
      };
      switch (this.$props.status) {
        case "C":
          status.text = "Cancelado";
          status.type = "danger";
          break;

        case "I":
          status.text = "Finalizado";
          status.type = "success";
          break;

        default:
          status.text = "Aberto";
          status.type = "primary";
          break;
      }
      return status;
    }
  }
};
</script>
