<template>
  <div class="col-lg-12 col-md-12">
    <div class="row">
      <div class="col-lg-3 col-md-3">
        <p class="text_pgto">Selecione a forma de pagamento:</p>
        <div class="form-check form-check-inline alinhamentoPagamento">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="vista"
            v-on:click="selectOptionPay('vista')"
            :disabled="statusOrcamento"
            :checked="'01 - A VISTA' == formaPagamento"
          />
          <label class="form-check-label" for="inlineRadio1">À vista</label>
        </div>
        <div class="form-check form-check-inline alinhamentoPagamento">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="prazo"
            v-on:click="selectOptionPay('prazo')"
            :disabled="statusOrcamento"
            :checked="'16 - RECTO - DUPLICATA / DU' == formaPagamento"
          />
          <label class="form-check-label" for="inlineRadio2">A Prazo</label>
        </div>
        <div class="form-check form-check-inline alinhamentoPagamento">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="cartao_credito"
            v-on:click="selectOptionPay('cartao')"
            :disabled="statusOrcamento"
            :checked="'77 - RECTO - CARTÃO VISA (BALCÃO)' == formaPagamento"
          />
          <label class="form-check-label" for="inlineRadio3"
            >Cartão de crédito</label
          >
        </div>
        <select
          class="form-control"
          id="selectPagamento"
          v-model="selectPayment"
          :disabled="statusOrcamento"
        >
          <option
            v-for="opt in optionsSelectPayment"
            :key="opt.form_pgto"
            :value="opt.form_pgto"
          >
            {{ opt.descricao }}
          </option>
        </select>
      </div>
      <div class="col-lg-9 col-md-9">
        <p class="text_pgto">Observação:</p>
        <textarea
          class="col-lg-12 col-md-12"
          cols="196"
          rows="3"
          id="textarea"
          maxlength="1000"
          :disabled="disabled"
          v-model="observacao_text"
          @keyup="charCount()"
        ></textarea>
        <span>{{ charResult }} caracteres restante</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <b-button
          class="mb-2 floatRight"
          variant="success"
          :disabled="disabled"
          v-on:click.prevent="salvarObservacaoePagamentoLocal('local')"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </div>
</template>
<style>
.text_pgto {
  font-weight: bold;
}

.alinhamentoPagamento {
  margin-bottom: 3%;
  margin-left: 13px;
}

#selectPagamento {
  width: 88%;
}

.floatRight {
  float: right;
}
</style>

<script>
import Orcamento from "../../services/orcamento";
import { EventBus } from "../../helpers/eventBus";
import GrupoPermissao from "../../services/grupoPermissao";

export default {
  name: "FormaPgto",
  props: {
    statusOrcamento: Boolean,
    obs: String,
    condPagamento: String,
    formaPagamento: String,
  },
  data: () => {
    return {
      forma_pagamento: "",
      optionPaymentForm: "",
      observacao_text: "",
      selectPayment: "",
      charResult: 1000,
      optionsSelectPayment: [],
      dados_pagamento: new Object(),
      disabled: false,
    };
  },
  beforeMount() {
    this.validaPermissao();
    this.orcamento_id = this.$route.params.id;
    this.forma_pagamento = this.formaPagamento;
    this.selectPayment = this.condPagamento;
    this.observacao_text = this.obs;
  },
  mounted() {
    this.showPagamento(this.forma_pagamento);
    EventBus.$on("salvarObsePgtoPedido", this.salvarObsePgtoPedido);
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/orcamentos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    selectOptionPay(value) {
      this.forma_pagamento = value;

      this.optionsSelectPayment = [];

      switch (this.forma_pagamento) {
        case "vista":
          this.optionPaymentForm = "01 - A VISTA";
          this.optionsSelectPayment = [
            { descricao: "Dinheiro", form_pgto: "01 - RECTO - DINHEIRO / CX" },
            {
              descricao: "Cartão de Débito",
              form_pgto: "04 - RECTO CARTÃO DEBIT VISA BALCÃO / CD",
            },
          ];
          break;
        case "prazo":
          this.optionPaymentForm = "16 - RECTO - DUPLICATA / DU";
          this.optionsSelectPayment = [
            { descricao: "1 parcela (30 dias)", form_pgto: "07 - 30 DIAS" },
            {
              descricao: "2 parcelas (30/60 dias)",
              form_pgto: "21 - 2 VEZES (30/60 DIAS)",
            },
            {
              descricao: "3 parcelas (30/60/90 dias)",
              form_pgto: "22 - 3 VEZES (30/60/90)",
            },
            {
              descricao: "4 parcelas (30/60/90/120 dias)",
              form_pgto: "23 - 4 VEZES (30/60/90/120)",
            },
          ];
          break;
        case "cartao":
          this.optionPaymentForm = "77 - RECTO - CARTÃO VISA (BALCÃO)";
          this.optionsSelectPayment = [
            { descricao: "1 parcela (30 dias)", form_pgto: "07 - 30 DIAS" },
            {
              descricao: "2 parcelas (30/60 dias)",
              form_pgto: "21 - 2 VEZES (30/60 DIAS)",
            },
            {
              descricao: "3 parcelas (30/60/90 dias)",
              form_pgto: "22 - 3 VEZES (30/60/90)",
            },
            {
              descricao: "4 parcelas (30/60/90/120 dias)",
              form_pgto: "23 - 4 VEZES (30/60/90/120)",
            },
          ];
          break;
        default:
          this.optionPaymentForm = null;
          this.optionsSelectPayment = null;
          break;
      }
    },
    charCount() {
      this.charResult = 1000 - this.observacao_text.length;
    },
    salvarObservacaoePagamentoLocal(localChamada) {
      var resp = this.salvarObservacaoePagamento();

      if (resp != undefined) {
        if (!resp) {
          this.$helper.showMsg("Selecione a forma de pagamento.", "warning");
        } else {
          this.dados_pagamento.forma_pagamento = this.optionPaymentForm;
          this.dados_pagamento.selectPayment = this.selectPayment;
          this.dados_pagamento.observacao_text = this.observacao_text;

          if (localChamada == "pedido") {
            Orcamento.saveObs(this.orcamento_id, this.dados_pagamento).then(
              EventBus.$emit("sendOrcamento")
            );
          } else {
            Orcamento.saveObs(this.orcamento_id, this.dados_pagamento);
          }
        }
      }
    },
    salvarObservacaoePagamento() {
      if (this.forma_pagamento && this.selectPayment) {
        console.log(
          "forma de pagamento não é nulo. Forma pgto: " +
            this.forma_pagamento +
            " e parcelas: " +
            this.selectPayment
        );
        return true;
      } else {
        if (
          this.forma_pagamento === undefined &&
          this.selectPayment === undefined
        ) {
          console.log("forma de pagamento indefinido " + this.forma_pagamento);
          return undefined;
        } else {
          console.log("É nulo!");
          return false;
        }
      }
    },
    showPagamento(fPagamento) {
      switch (fPagamento) {
        case "01 - A VISTA":
          fPagamento = "vista";
          break;
        case "16 - RECTO - DUPLICATA / DU":
          fPagamento = "prazo";
          break;
        case "77 - RECTO - CARTÃO VISA (BALCÃO)":
          fPagamento = "cartao";
          break;
        default:
          fPagamento;
          break;
      }

      if (fPagamento != "") {
        this.selectOptionPay(fPagamento);
      }
    },
    salvarObsePgtoPedido() {
      this.salvarObservacaoePagamentoLocal("pedido");
    },
  },
  beforeDestroy() {
    EventBus.$off("salvarObsePgtoPedido", this.salvarObsePgtoPedido);
  },
};
</script>